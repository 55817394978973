import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Button, Card, Container, Media, Row, Col } from 'reactstrap';

import Navbar from '../components/navbar.jsx';
import Footer from '../components/footer.jsx';

import { EmailLink } from '../components/helper.js';


class Contacts extends React.Component {

  componentDidMount() {
    //window.scrollTo(0, 0);
  }

  render() {
    const { helper } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Kontakty | culkafruit</title>
        </Helmet>
        <Navbar>
          <Link to="/#pobocky" className="nav-link nav-link-icon">
            <i className="fa fa-shop d-lg-none mr-2" />
            <span className="nav-link-inner--text">Pobočky</span>
          </Link>
        </Navbar>
        <main className="profile-page" ref="main">
          <section className="section-cover section-shaped my-0">
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <div
              className="separator separator-bottom separator-skew"
              style={{marginBottom: '-1px'}}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section mb-sm-3 mb-lg-5">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require("../assets/img/brand/logo.png")}
                        />
                      </div>
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                      <div className="card-profile-actions py-4 mt-lg-0">
                        <Button
                          className="mr-4"
                          color="facebook"
                          href="#"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          Napište nám
                        </Button>
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="4">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading"><span className="description" style={{textTransform: 'none'}}>viac ako</span><br />20 rokov</span>
                          <span className="description">skúseností</span>
                        </div>
                        <div className="align-self-end">
                          <span className="heading">100%</span>
                          <span className="description">spoľahlivosť</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <h3>
                      Culka fruit
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Bratislava, Slovensko
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Predávame domáce aj exotické ovocie a zeleninu,<br />bylinky, oleje aj netypické sladkosti.
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top">
                    <Row className="justify-content-center">
                      <Col md="5" lg="4">
                        <Media className="d-flex mb-3">
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white mt-1">
                            <i className="fa fa-user" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-0 mb-md-1">
                              Michal Culka
                            </h6>
                            <p className="description d-md-inline-block mb-0">
                              <EmailLink to={'michal.culka'} className={'text-body'} />
                            </p>
                          </Media>
                        </Media>
                      </Col>
                      <Col md="5" lg="4">
                      <Media className="d-flex mb-3">
                        <div className="icon icon-shape bg-gradient-warning rounded-circle text-white mt-1">
                          <i className="fa fa-home" />
                        </div>
                        <Media body className="ml-3">
                          <h5 className="heading text-warning mb-0 mb-md-1">
                            Pobočky
                          </h5>
                          <p className="description d-md-inline-block mb-0">
                            <EmailLink to={'culkafruit'} className={'text-body'} />
                          </p>
                        </Media>
                      </Media>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <div dangerouslySetInnerHTML={{__html:  `<!-- Load Facebook SDK for JavaScript -->
        <div id="fb-root"></div>
        <script>
          console.log('Ne');
        window.fbAsyncInit = function() {
          FB.init({
            xfbml            : true,
            version          : 'v3.2'
          });
        };

        (function(d, s, id) {
          var js, fjs =3D d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id =3D id;
          js.src = 'https://connect.facebook.net/sk_SK/sdk/xfbml.customerchat.js'        ;
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));</script>

        <!-- Your customer chat code -->
        <div class=3D"fb-customerchat"
          attribution=3Dinstall_email
          page_id=3D"1735353386719416"
          theme_color=3D"#7CA32E"
          logged_in_greeting=3D"Dobr&#xfd; de&#x148;, ako V&#xe1;m
        m&#xf4;&#x17e;eme pom&#xf4;c&#x165;? :)"
          logged_out_greeting=3D"Dobr&#xfd; de&#x148;, ako V&#xe1;m
        m&#xf4;&#x17e;eme pom&#xf4;c&#x165;? :)">
      </div>`}} />
        <Footer />
      </React.Fragment>
    );
  }

}

export default Contacts;
